import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { CHILDREN_PROP_TYPES } from '../../../constants';

const Styles = (props) => ({
  backgroundColor: props.secondary
    ? props.theme.colors.bloodRed
    : props.theme.colors.navy,
  borderRadius: '50px',
  color: props.theme.colors.white,
  display: 'inline-block',
  fontSize: props.theme.fontSizes.button,
  fontWeight: props.theme.fontWeights.bold,
  fontFamily: props.theme.fonts,
  padding: `${props.theme.space[3]}px ${props.theme.space[6]}px`,
  textDecoration: 'none',
  width: 'auto',
  border: 0,
});

const StyledButton = styled.button`
  ${(props) => Styles(props)}
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 16px 24px;
  }
`;

const StyledLink = styled(Link)`
  ${(props) => Styles(props)}
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 16px 24px;
  }
`;

const StyledExternalLink = styled.a`
  ${(props) => Styles(props)}
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 16px 24px;
  }
`;

const Button = ({
  children,
  onClick,
  path,
  isExternal = false,
  disabled = false,
}) => {
  if (path) {
    if (isExternal) {
      return (
        <StyledExternalLink
          href={path}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </StyledExternalLink>
      );
    } else {
      return <StyledLink to={path}>{children}</StyledLink>;
    }
  } else {
    return (
      <StyledButton onClick={onClick} disabled={disabled}>
        {children}
      </StyledButton>
    );
  }
};

export default Button;

Button.defaultProps = {
  onClick: null,
  path: null,
};

Button.propTypes = {
  children: CHILDREN_PROP_TYPES,
  onClick: PropTypes.func,
  path: PropTypes.string,
};
