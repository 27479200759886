import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { getGatsbyImageData } from 'gatsby-source-sanity';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/common/container';
import Footer from '../components/common/footer';
import BlockContent from '../components/portable-text';
import Button from '../components/common/button';

const Hero = styled.div`
  background: linear-gradient(180deg, #c63d3d 0%, #d20707 100%);
  position: relative;
  padding: 84px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 32px;
  }

  @media screen and (min-width: 768px) {
    padding: 84px 84px 0;
  }

  svg {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45%;
    height: 100%;
    fill: #eaeaea;

    @media screen and (min-width: 1200px) {
      display: block;
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  @media screen and (min-width: 768px) {
    min-height: 650px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 720px;
    text-align: left;
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 35px;
  font-weight: 800;
  line-height: 1.25;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    font-size: 64px;
    line-height: 80px;
  }
`;

const Players = styled(Img)`
  display: none !important;
  position: absolute !important;
  right: -5%;
  top: 55%;
  transform: translateY(-50%);
  width: 600px;
  z-index: 10;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.45);

  @media screen and (min-width: 1200px) {
    display: block !important;
  }

  @media screen and (min-width: 1350px) {
    width: 700px;
    top: 55%;
  }

  @media screen and (min-width: 1500px) {
    right: 56px;
  }
`;

const ArticleGrid = styled.div`
  padding-bottom: ${(props) => props.theme.space[6]}px;
  padding-top: ${(props) => props.theme.space[6]}px;
  max-width: 1080px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    padding-bottom: ${(props) => props.theme.space[8]}px;
    padding-top: ${(props) => props.theme.space[8]}px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: ${(props) => props.theme.space[4]}px;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: ${(props) => props.theme.space[4]}px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: ${(props) => props.theme.space[6]}px;
    }
  }

  ul {
    margin-bottom: ${(props) => props.theme.space[4]}px;
    list-style-type: disc;
    padding-left: 2rem;

    @media screen and (min-width: 768px) {
      margin-bottom: ${(props) => props.theme.space[6]}px;
    }

    li {
      line-height: 1.5;
      margin-bottom: ${(props) => props.theme.space[2]}px;

      @media screen and (min-width: 768px) {
        font-size: 1.5rem;
        margin-bottom: ${(props) => props.theme.space[4]}px;
      }
    }
  }

  h3 {
    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }

  strong {
    font-weight: 600;
  }

  figure {
    margin-bottom: ${(props) => props.theme.space[6]}px;
    margin-top: ${(props) => props.theme.space[6]}px;
    font-size: 1.2rem;
    font-weight: 600;

    .gatsby-image-wrapper {
      margin-bottom: ${(props) => props.theme.space[4]}px;
    }
  }

  a {
    color: #000000;
    font-weight: 600;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.space[6]}px;

  @media screen and (min-width: 768px) {
    margin-top: ${(props) => props.theme.space[8]}px;
  }

  a {
    color: #ffffff;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
      padding: 16px 24px;
    }
  }
`;

const News = ({ data }) => {
  const imageId = data.sanityPost.featureImage.asset._id;

  const imageProps = getGatsbyImageData(
    imageId,
    {
      maxWidth: 1020,
    },
    {
      projectId: 'pfgbkjj1',
      dataset: 'production',
    }
  );

  return (
    <Layout isHomepage>
      <SEO title={`Club News ${data.sanityPost.title}`} />
      <Hero>
        <Wrap>
          <Title>{data.sanityPost.title}</Title>
        </Wrap>

        {data.sanityPost.featureImage && <Players fluid={imageProps} />}

        <svg viewBox="0 0 100 100" preserveAspectRatio="none">
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop
                offset="0%"
                style={{ stopColor: 'rgb(45, 56, 97)', stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: 'rgb(55, 69, 132)', stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
          <polygon points="0, 100 100, -300 100, 100" fill="url(#grad1)" />
        </svg>
      </Hero>

      <Container>
        <ArticleGrid>
          <main>
            <BlockContent blocks={data.sanityPost._rawBody} />

            <ButtonWrap>
              <Button path="/news">Back to Club News</Button>
            </ButtonWrap>
          </main>
        </ArticleGrid>
      </Container>

      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    sanityPost(slug: { current: { eq: $slug } }) {
      title
      _rawBody
      featureImage {
        asset {
          _id
        }
      }
    }
  }
`;

export default News;
